import React from "react"
import Layout from "@components/layout"
import FormsParts from "@components/forms-contactform7";

// WP CSS
import "@css/@wordpress/block-library/build-style/style.css"
import "@css/@wordpress/block-library/build-style/theme.css"

// コンポーネント呼び出し
import { SearchCategList } from "@components/search-categList.js"; // カテゴリ検索機能
import { RelatedContents } from "@components/related-article.js"; // 関連記事取得・形成
import { FixedFoot } from "@components/footer";
import { WordSearch } from "@components/word-search.js";
import { ProfileContents } from "@components/profile.js";
import { SeoContents } from "@components/seo-data.js" // Seo情報設定

const PropContact = () => {
  const indexUrl = '/';
  const typeUrl = 'contact#how';
  const pageTitle = 'お客様サポート'
  const pageSubTitle = 'Contact form';
  const LinkBeginnerL = indexUrl + "faq#how";
  const LinkBeginnerR = indexUrl + typeUrl;

  let fixedItem = FixedFoot( 'nomal' ); // 固定フッター
  
  // Seo情報設定
  const pathName = 'contact';
  const seoTag = SeoContents({ Path:pathName });
                
  return (
    <>
      <Layout>
        {seoTag}
        <div className="bg-[#F6F6F6] md:pb-[70px] pb-2">
          <div className="px-5 max-w-xl mx-auto md:max-w-5xl ">
            <div className="pb-4 pt-0 md:pt-10  pt-0  max-w-[800px] ">
              <h2 className="md:text-5xl text-3xl font-black mb-5 text-left pt-5 !leading-[1.2]">{pageTitle}</h2>
              <h3 className="md:text-3xl text-2xl font-black  text-left  text-[#1F55A6]">{pageSubTitle}</h3>
            </div>
          </div>
        </div>
        
        {/* サブメニュー */}
        <div className="bg-[#F6F6F6]">
        <a id="how" className="jump_point"></a>
          <div className="max-w-5xl mx-auto flex px-5">
            <a href={LinkBeginnerL} className="md:text-base text-xs text-center py-4 px-2 md:px-10 w-1/2 bg-white mr-3 font-semibold border-b-[15px] border-[#F6F6F6] hover:text-[#1F55A6] text-[#999999] hover:border-white">
              よくある質問
            </a>
            <a href={LinkBeginnerR} className="md:text-base text-xs text-center py-4 px-2 md:px-10 w-1/2 bg-white ml-3 font-semibold border-b-[15px] border-white text-[#1F55A6]">
              お問い合わせ
            </a>
          </div>
        </div>

        {/* 本文 */}
        <div className="max-w-xl mx-auto md:max-w-5xl mt-[70px] px-5">
          <div>
            <h3 className="md:text-3xl text-2xl font-black mb-10 text-left  text-[#1F55A6] mt-[70px] ">お問い合わせ</h3>
            <p className="mb-[30px] max-w-[300px] md:max-w-[960px] overflow-hidden">
            Just tradeについてのお問合せは下記お問合せフォームよりお気軽にお問い合わせください。<br></br>
            2~3営業日以内に、担当者よりご連絡いたします。
            </p>
          </div>
        </div>
        
        {/* コンタクトフォーム */}
        <div className="bg-[#F6F6F6] pt-[70px]">
          <div className="max-w-xl mx-auto md:max-w-5xl px-5">
          <div className="pb-[50px]"><FormsParts/></div>

          </div>  
        </div>
        
        {/* 固定フッター */}
        {fixedItem}
      </Layout>
    </>
  )
}
export default PropContact
